@import "~core/abstract/all";
@import "~bootstrap/scss/mixins";

.b-image-and-text {
    padding: 0;
    text-align: center;

    &__text {
        font-size: $fs-medium;
        margin: $spacer-20 0;

        @include media-breakpoint-up(ls) {
            font-size: $fs-large;
        }

        + .b-image-and-text__link {
            margin-top: 0;
        }
    }

    &__image {
        display: block;
    }

    &__link {
        font-weight: $fw-bold;
        letter-spacing: $ls-xl;
        text-decoration: underline;
        text-transform: uppercase;
        margin-top: $spacer-10;
        display: inline-block;
    }

    h2 {
        font-size: clamp(24px, 2vw, 36px);
    }
}

.b-image-bg-and-text {
    padding: 0;
    position: relative;
    margin-bottom: $spacer-40;

    &__text {
        padding: $spacer-35 0;
        position: absolute;
        width: 100%;
        color: $color-black-text;
        text-align: center;
        font-size: $fs-xxl;
        font-weight: $fw-medium;
        text-transform: none;
        letter-spacing: $ls-xxxl;

        @include media-breakpoint-up(ls) {
            font-size: $fs-xxxl;
        }

        @include media-breakpoint-up(xl) {
            font-size: 62px;
        }
    }
}

.product-carousel {
    h2 {
        font-family: 'Public Sans';
        text-transform: none;
        font-size: 16px;
        margin-bottom: 8px;
    }

    p {
        font-family: 'Inter';
        font-size: 14px;
        margin-top: 8px;
    }
}
